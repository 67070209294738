import axios from 'axios';

import { toastOnError } from './Utils';

// Set url paths to backend
export const utilsURL = 'https://mp-pay.n3connect.com/utils';
export const mainURL = 'https://mp-pay.n3connect.com/ohip/v0';

const utilsAxios = axios.create({
    baseURL: utilsURL
})

export const getCountries = async (countryId) => {
    try {
        const response = await utilsAxios.get(`/countries?country=${countryId}`);
        return response.data;
    } catch (error) {
        toastOnError(error);
        return error;
    }
}


export const getRegions = async (countryId, regionId) => {
    try {
        const response = await utilsAxios.get(`/regions?country=${countryId}&region=${regionId}`);
        return response.data;
    } catch (error) {
        toastOnError(error);
        return error;
    }
}


export const getCities = async (countryId, regionId, cityId) => {
    try {
        const response = await utilsAxios.get(`/cities?country=${countryId}&region=${regionId}&city=${cityId}`);
        return response.data;
    } catch (error) {
        toastOnError(error);
        return error;
    }
}


export const getDisclaimers = async (key) => {
    try {
        const response = await utilsAxios.get(`/disclaimers?key=${key}`);
        return response.data;
    } catch (error) {
        toastOnError(error);
        return error;
    }
}